var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "primary--text font-weight-bold" }, [
          _vm._v("Clone Space")
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c("v-subheader", { staticClass: "pl-0" }, [
            _vm._v("Select source space")
          ]),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.sourceOrgs,
                      label: "Source Organization",
                      outlined: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.getOrgData()
                      }
                    },
                    model: {
                      value: _vm.sourceOrg,
                      callback: function($$v) {
                        _vm.sourceOrg = $$v
                      },
                      expression: "sourceOrg"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.spaces,
                      label: "Source Space",
                      loading: _vm.fetching === true,
                      disabled: _vm.fetching === true,
                      outlined: ""
                    },
                    model: {
                      value: _vm.sourceSpace,
                      callback: function($$v) {
                        _vm.sourceSpace = $$v
                      },
                      expression: "sourceSpace"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-subheader", { staticClass: "pl-0" }, [
            _vm._v("Select target organization")
          ]),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { cols: "6" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.targetOrgs,
                      label: "Target Organization",
                      disabled:
                        _vm.fetching === true ||
                        !_vm.sourceOrg.length ||
                        !_vm.sourceSpace.length,
                      outlined: ""
                    },
                    model: {
                      value: _vm.targetOrg,
                      callback: function($$v) {
                        _vm.targetOrg = $$v
                      },
                      expression: "targetOrg"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-checkbox", {
            attrs: { label: "Clone the Master instance only" },
            model: {
              value: _vm.masterOnly,
              callback: function($$v) {
                _vm.masterOnly = $$v
              },
              expression: "masterOnly"
            }
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    color: "secondary",
                    depressed: "",
                    loading: _vm.cloningSpace,
                    disabled:
                      !_vm.sourceOrg ||
                      !_vm.sourceOrgs ||
                      !_vm.targetOrg ||
                      _vm.masterOnly === null ||
                      _vm.masterOnly === undefined
                  },
                  on: { click: _vm.cloneSpace }
                },
                [_vm._v("clone")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }