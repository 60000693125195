<template>
    <v-card flat>
        <v-card-title><span class="primary--text font-weight-bold">Clone Space</span></v-card-title>
        <v-card-text>
            <v-subheader class="pl-0">Select source space</v-subheader>
            <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                    <v-select :items="sourceOrgs" label="Source Organization" v-model="sourceOrg" @change="getOrgData()" outlined></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                    <v-select
                        :items="spaces"
                        label="Source Space"
                        v-model="sourceSpace"
                        :loading="fetching === true"
                        :disabled="fetching === true"
                        outlined
                    ></v-select>
                </v-col>
            </v-row>
            <v-subheader class="pl-0">Select target organization</v-subheader>
            <v-row align="center">
                <v-col class="d-flex" cols="6">
                    <v-select
                        :items="targetOrgs"
                        label="Target Organization"
                        v-model="targetOrg"
                        :disabled="fetching === true || !sourceOrg.length || !sourceSpace.length"
                        outlined
                    ></v-select>
                </v-col>
            </v-row>
            <v-checkbox v-model="masterOnly" label="Clone the Master instance only"></v-checkbox>
            <div class="d-flex justify-center">
                <v-btn
                    color="secondary"
                    depressed
                    class="mr-1"
                    @click="cloneSpace"
                    :loading="cloningSpace"
                    :disabled="!sourceOrg || !sourceOrgs || !targetOrg || masterOnly === null || masterOnly === undefined"
                    >clone</v-btn
                >
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data: () => ({
        items: ['a', 'b'],
        sourceOrg: '',
        sourceSpace: '',
        orgSpaces: [],
        targetOrg: '',
        fetching: false,
        masterOnly: false,
        cloningSpace: false
    }),
    computed: {
        ...mapState(['userOrgs']),
        sourceOrgs: function() {
            return this.userOrgs.map(org => org.long_id)
        },
        targetOrgs: function() {
            if (this.sourceOrg.length) {
                return this.userOrgs.map(org => org.long_id).filter(org => org !== this.sourceOrg)
            }
            return []
        },
        spaces: function() {
            if (this.$data.orgSpaces.length > 0) {
                return this.$data.orgSpaces.map(space => space.long_id)
            }
            return []
        }
    },
    methods: {
        getOrgData: function() {
            this.$data.fetching = true
            this.$data.sourceSpace = ''
            this.$data.targetOrg = ''
            this.$data.error = false
            const oid = this.userOrgs.filter(org => org.long_id === this.sourceOrg)[0].oid
            this.$axios
                .get(`/orgs/${oid}/spaces`)
                .then(response => {
                    this.$data.orgSpaces = response.data
                })
                .catch(() => {
                    this.$data.error = true
                })
                .finally(() => {
                    this.$data.fetching = false
                })
        },
        cloneSpace: function() {
            this.$data.cloningSpace = true
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Cloning the space...',
                snackBarTimeout: 10000,
                snackBarIcon: 'info'
            })
            const sid = this.orgSpaces.filter(space => space.long_id === this.sourceSpace)[0].sid
            const oid = this.userOrgs.filter(org => org.long_id === this.targetOrg)[0].oid
            this.$axios
                .post(`/spaces/${sid}/clone_to_org/${oid}`, {
                    master_only: this.$data.masterOnly
                })
                .then(() => {})
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'An error has occurred, please try later.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.cloningSpace = false
                })
        }
    }
}
</script>
